import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { AccountId } from '@wanda-space/types'
import type { SupportedCountries } from '../consts/enums'
import { useAccount } from '../hooks/useAccount'
import { useProducts } from '../hooks/useProducts'

export function Product({ productId, accountId }: { productId: string; accountId: AccountId }) {
  const { data: user } = useAccount(accountId)
  const { data: products } = useProducts({
    country: user?.countryCode as SupportedCountries,
    id: productId,
  })

  if (!user && !products) {
    return <>{productId}</>
  }
  const localisationKey = products?.products.find((prod) => prod.id === productId)?.localizationKey

  return <FormattedMessage id={`${localisationKey}.name`} />
}
